.pdfViewer .page {
  border: none;
  box-sizing: content-box;
  margin: 20px auto;
}

#focusreader-bar,
#focusreader-bar-background,
#focusreader-bar-dimmer-top,
#focusreader-bar-dimmer-right,
#focusreader-bar-dimmer-bottom,
#focusreader-bar-dimmer-left {
  display: none;
}

.focusreader-lockedin #focusreader-bar {
  display: block;
  width: 200px;
  height: 2px;
  position: absolute;
  background: black;
  opacity: 1;
  transition: opacity 400ms;
}

.focusreader-lockedin #focusreader-bar.hidden {
  opacity: 0;
}

.focusreader-lockedin #focusreader-bar-background {
  display: block;
  height: 2px;
  position: absolute;
  background: lightgray;
}

.focusreader-lockedin #focusreader-bar-adjust {
  display: block;
  height: 2px;
  position: absolute;
  background: transparent;
  border: dashed 1px black;
  transition: opacity 400ms;
}

.focusreader-lockedin #focusreader-bar-adjust.hidden {
  opacity: 0;
}

.focusreader-lockedin #focusreader-bar-dimmer-top,
.focusreader-lockedin #focusreader-bar-dimmer-right,
.focusreader-lockedin #focusreader-bar-dimmer-bottom,
.focusreader-lockedin #focusreader-bar-dimmer-left {
  display: block;
  position: absolute;
  background: rgba(255,255,255, 0.8);
}